<template>
  <b-modal
    id="resend-nf-modal"
    size="md"
    hide-header
    hide-footer
    content-class="modal__container"
  >
    <div class="modal-header">
      <div class="modal-title">Reenvio de NFS-e</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <p class="p-3 modal__content">
      Você gostaria de reenviar a nota emitida por
      <span>
        {{ nfData.razao_social_prestador }}
      </span>
      ao cliente?

      <br />
      <br />

      Reenviar para <i> {{ nfData.email_tomador }} </i>
    </p>

    <div class="my-4 d-flex justify-content-end">
      <b-button variant="outline-primary" class="mr-3" @click="closeModal">
        Cancelar
      </b-button>

      <b-button variant="primary" @click="resendEmail"> Confirmar </b-button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/modules/nfe/api'

export default {
  name: 'ResendNfModal',
  props: {
    nfData: Object
  },
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  data() {
    return {}
  },
  methods: {
    async resendEmail() {
      const isLoading = this.$loading.show()

      try {
        await api.resendEmail(this.nfData.id)

        this.$toast.success('E-mail reenviado com sucesso!')
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.hide()
        this.closeModal()
      }
    },
    closeModal() {
      this.$bvModal.hide('resend-nf-modal')
    }
  }
}
</script>

<style lang="scss">
#resend-nf-modal {
  .modal-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans';
    color: var(--type-active);
  }

  .icon-box {
    width: 24px;

    svg {
      fill: var(--neutral-500);
    }
  }

  .modal__container {
    margin-top: 14rem;
  }

  .modal__content {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Nunito Sans';
    color: var(--type-active);

    > span {
      font-weight: 700;
    }
  }
}
</style>
